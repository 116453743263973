import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'MetaPlus',
  description:
    'The most popular AMM on BSC by user count! Earn PLUS through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by MetaPlus), NFTs, and more, on a platform you can trust.',
  image: 'https://metachainplus.org/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('MetaPlus')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('MetaPlus')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('MetaPlus')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('MetaPlus')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('MetaPlus')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('MetaPlus')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('MetaPlus')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('MetaPlus')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('MetaPlus')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('MetaPlus')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('MetaPlus')}`,
      }
    default:
      return null
  }
}
